import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/upload/:token',
        name: 'comicUpload',
        component: () => import('@/views/upload/comicUpload'),
    },
    {
        path: '/report/:id/:token',
        name: 'reportMsg',
        component: () => import('@/views/video/reportMsg'),
    },
    {
        path: '/comicReport/:id/:token',
        name: 'comicReport',
        component: () => import('@/views/comic/comicReport'),
    },
    {
        path: '/banner',
        name: 'banner',
        component: () => import('@/views/banner/bannerIndex'),
    },
    {
        path: '/payMenu',
        name: 'payMenu',
        component: () => import('@/views/pay/payMenu'),
    },
    {
        path: '/paySucc/:token',
        name: 'paySucc',
        component: () => import('@/views/pay/paySucc'),
    },
    {
        path: '/payCancel/:token',
        name: 'payCancel',
        component: () => import('@/views/pay/payCancel'),
    },
    {
        path: '/webPay/:token',
        name: 'webPay',
        component: () => import('@/views/web_pay/webPay'),
    },


    {
        path: '/artMenu/:dis_tm',
        name: 'artMenu',
        component: () => import('@/views/artavo/artMenu'),
    },
    {
        path: '/artSucc/:token',
        name: 'artSucc',
        component: () => import('@/views/artavo/artSucc'),
    },
    {
        path: '/artCancel/:token',
        name: 'artCancel',
        component: () => import('@/views/artavo/artCancel'),
    },

    /* ms stp */
    {
        path: '/webMenu/:dis_tm',
        name: 'webMenu',
        component: () => import('@/views/mospix/webMenu'),
    },
    {
        path: '/webSucc/:is_life/:token',
        name: 'webSucc',
        component: () => import('@/views/mospix/webSucc'),
    },
    {
        path: '/webCancel/:token',
        name: 'webCancel',
        component: () => import('@/views/mospix/webCancel'),
    },
    /* ms air */
    {
        path: '/aWebMenu/:dis_tm',
        name: 'aWebMenu',
        component: () => import('@/views/aMospix/aWebMenu'),
    },
    {
        path: '/aWebSucc/:is_life/:token',
        name: 'aWebSucc',
        component: () => import('@/views/aMospix/aWebSucc'),
    },
    {
        path: '/aWebCancel/:token',
        name: 'aWebCancel',
        component: () => import('@/views/aMospix/aWebCancel'),
    },

    {
        path: '/webAndSucc/:token',
        name: 'webAndSucc',
        component: () => import('@/views/pay/webSucc'),
    },
    {
        path: '/webAndCancel/:token',
        name: 'webAndCancel',
        component: () => import('@/views/pay/webCancel'),
    },

    /* mb air */
    {
        path: '/aMbMenu/:pay_id/:token',
        name: 'aMbMenu',
        component: () => import('@/views/aMb/aMbMenu'),
    },

    // 其他路由配置...
    {
        path: '/:pathMatch(.*)*', // Vue Router 4 的通配符语法
        redirect: '/', // 重定向到首页
    },
    {
        path: '/',
        name: 'emptyIndex',
        component: () => import('@/views/empty/emptyIndex'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

export default router;